<template>
 <div >

     <XModal :name="name" :resizable="true"  width='85%' height="86%" :draggable="false" >
       <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
         <div class='appHead'>
             <div style='display: inline-flex; color: #444; font-weight: bold; padding-left: 5pt; padding-top: 3pt; font-size: 10pt; float: left;'>
             <span v-if="headerData.placement">{{tr('Placement')}} Revenues</span><span v-else>{{tr('Placement')}} editor</span> 
             </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div v-if="ready" class='mainContent dontWrap'  >
                <div :style="getAppStyle()+'float: top; width: 100%;'" class="title">{{headerData.placement.name}}
                     
					 <span style="padding-left: 10pt; padding-top: 1pt;" class="small">Budget {{formatNumber(headerData.placement.budget)}} {{headerData.info.currency}}</span>
                </div>
             	
			    <div style='width: 100%; height: calc(70vH); overflow-y: auto; float: left;'>
					

					
						<div class="cellTitle head">
						   Channel/Site
						</div>
						<div class="cellInp head">
						   Budget
						</div>
						<div class="cellInp head">
						   Add
						</div>
						<div class="cell head" v-for="(mnth,mi) in headerData.info.months" :key="'m'+mi">
						  {{mnth.substring(0,3)}} ({{headerData.info.daysInMonth[mnth]}})
						</div>
						<div>
						<div class="cellTitle head2">
							Totals
						</div>
						<div class="cellInp head2" :x="x">
						   {{formatNumber(Object.values(budgetTable).reduce((a,b)=>a+b)+Object.values(budgetTableAdd).reduce((a,b)=>a+b))}}
						</div>
						<div class="cellInp head2" :x="x">
						   {{Object.values(budgetTableAdd).reduce((a,b)=>a+b)}}
						</div>
						<div class="cell head2" v-for="(mnth,mi) in headerData.info.months" :key="'m'+mi">
						  {{getRevenueMonth( mnth)}}
						 </div>
					      </div>
			    	<div v-if="ready && data.sites" style='float: top; width: 100%; width: 100%;'>
			    		<div v-for="(site,si) in data.sites" :key="'site'+si">
							<div :class="site.adTax?'cellTitle':'cellTitleNOADTAX'">
								<span v-if="!site.adTax" style='display: block;font-size: 6pt; padding: 0;float: right;'>Additional costs</span>
							    <span style='display: block;float:left;'>{{site.label}}</span>
							</div>
							<div class="cellInp">
								<InputMoney v-model="budgetTable[site.id]" @changed="x++" :clipboard="false" />
							</div>	
							<div class="cellInp">
								<InputMoney v-model="budgetTableAdd[site.id]" @changed="x++" :clipboard="false" />
							</div>
							<div class="cell" v-for="(mnth,mi) in headerData.info.months" :key="'m'+mi">
							  {{getRevenue( mnth, site)}}
							</div>	
			    	    </div>
			    		
			    	</div> 
					
		    	</div>  <!-- TABLE -->
				<div style='position: sticky; width: 100%; bottom: 50pt !important; height: 30pt; padding-right: 15pt;'>
							<Button v-if="data.placement.id" title="reload data" style='float:right;' class='button' @click="loadData(headerData)"><mdicon size="18" name="reload"/></button>
							
							<Button title="close popup" style='float:right;' class='button' @click="close"><mdicon :size='18' name='close'/></button>
						    <Button v-if="data.placement.id" title="reload data" style='float:right;' class='button' @click="save"><mdicon size="18" name="content-save"/></button>		
					</div>
            </div> <!-- float: left -->
            </slot>
			</div> <!-- mainContent -->
	        
	    </div> <!-- modal-body -->
	    
	
	  </XModal> <!-- modal-container -->  	  
	
	<ContextMenu ref="menu" offsetY=120>
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem >
          What ever
        </ContextMenuItem>
        <ContextMenuItem >
          What else
        </ContextMenuItem>
        <ContextMenuSep/>
        
       </template>
    </ContextMenu>
 
 </div>
</template>

<script>
import {HTTP, fwAPI, placementServiceAPI, bngAPI, setReload, showError, myLocale, formatNumber } from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2 } from '@/basicTimeFN.js';
import { getAppStyle, initAppMode, isDarkMode } from '@/AppStyle.js';
import { format, formatNZ } from '@/Numbers.js';
import InputTime from '@/components/inputElements/InputTime2';
import InputMoney from '@/components/inputElements/InputMoney';
import Switch from '@/components/Switch';
import { tr } from '@/translate.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  name: 'GFW DEAL View',
  components : {
   ContextMenu,ContextMenuItem,ContextMenuSep
   , InputMoney
  },
  props: {
	name: {
	      type: String,
	      default: 'LineItemSiteRev'
	    },
	currentLine: Object,
  },
  data () {
    return {
    	data: {},
		x: 0,
		budgetTable: {},
		budgetTableAdd: {},
    	headerData: {},
    	getAppStyle, format, formatNumber, formatNZ, tr,
    	ready: false,
      }
  },
  methods: {
     getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
    
     myLocale() { return myLocale();},
      getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100% - 200px); padding: 1ex;"
    },
	
	open( data) 
	{ 
        if ( data.placementId )
		{
			this.loadPlacementData( data.placementId )
		}
		else
		{
	     	this.headerData = data; 
	     	this.loadData( this.headerData);
			this.$modal.show( this.name)
		}
     },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
     decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
	 getRevenue( mnth, site)
	 {
		if ( this.headerData.info.montlyRevenue[mnth])
		{
			let data = this.headerData.info.montlyRevenue[mnth].find(p=>p.siteId==site.id);
			if ( data )
			{
				return formatNumber(data.budget);
			}
		}
		return "";
	 },
	 getRevenueMonth( mnth)
 	 {
		let budget = 0;
		let values = this.headerData.info.montlyRevenue[mnth];
		for ( let i in values)
		{
			budget += values[i] ? values[i].budget : 0;
		}
 		if ( budget )
 		{
 			return formatNumber(budget);
 		}
 		return "";
 	 },
     save()
	 {
		 let externalRevenue = [];
		 for ( let si in this.data.sites)
		 {
			let site = this.data.sites[si];
			let budget = this.budgetTable[ site.id]
			let add = this.budgetTableAdd[ site.id]
			if ( !budget )
			{
				budget = add
			}
			else if ( add )
			{
				budget = budget + add;
			}
			if ( budget && budget > 0 )
			{
				externalRevenue.push( {siteId: site.id, budget: budget})
			}
		 }
		 
		 this.data.placement.externalRevenue = { list: externalRevenue};
		 
		 this.$emit('placement', this.data.placement, this.data)
	 },
     close() 
	 { 
		this.data = {};
		this.$modal.hide( this.name) 
	 },
	 loadPlacementData( placementId)
	 {
		let that = this;
		HTTP.post( placementServiceAPI+"/getPlacement4ExternalRevenue/"+sessionStorage.tenantId
   				+"/"+sessionStorage.accessPointId
   				+"/"+sessionStorage.unitId
   				+"/"+sessionStorage.userId
   				+"/"+placementId
   				)

    		 .then( response => 
             {
				
             	let data = response.data;
				//alert( JSON.stringify( data.sites))
				//that.types = response.data.types;
				
				data.sites = data.sites.map( p=> { return {id: p.id, label: (p.adTax?" ":"")+ p.shortname + " ("+p.costCenter+")", visible: true, adTax: p.adTax}});
													
				data.sites.sort( (a,b)=>a.label.localeCompare(b.label))
				that.headerData = {type: 'PLACEMENT', sites: data.sites, placement: data.list[0].placement, info: data.list[0]};
				//alert( JSON.stringify(that.headerData))
		     	that.loadData( that.headerData);
				that.$modal.show( that.name)
              
                
             }).catch(e => {
				   console.log(e)
            	    showError( that.$toast, "get copy data", e);
            	    that.showProgressBar = false;
                   
                });
	 },
     loadData( headerData)
     {
		 
		 if (!this.data)
		 {
		 	this.data = {}
		 }
    	 this.data.placement = headerData.placement;
		 this.data.sites = headerData.sites;
		 this.data.budget = headerData.placement.externalRevenue && headerData.placement.externalRevenue.list ? headerData.placement.externalRevenue.list: null;
		 if ( !this.data.budget || !this.data.budget.length)
		 {
			this.data.budget = [];
			for ( let si in this.data.sites)
			{
				let site = this.data.sites[si];
				this.data.budget.push( {siteId: site.id, budget: 0})
				
			}
		 }
		 
		 this.budgetTable = {};
		 for ( let si in this.data.sites)
		 {
			let site = this.data.sites[si];
			
			let entry = this.data.budget.find( p=>p.siteId == site.id);
			this.budgetTable[ site.id] = entry ? Math.round(entry.budget*100)*.01: 0;
			//alert( site.label+"-->"+this.budgetTable[ site.id])
			this.budgetTableAdd[ site.id] = 0;
		 }
		 //alert( JSON.stringify( this.headerData.info.daysInMonth))
		 this.ready = true;
     },
  },
  watch: {
	currentLine: function() 
	{ 
		if ( this.currentLine && this.ready ) 
		{
			this.headerData = this.currentLine; this.loadData( this.headerData)
		}
	}
	
  },
  created() {
	
  }
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  
}
.title { 
  display: block;
  align-items: left;
  justify-content: left;
  font-size: 10pt; 
  padding-left: 5pt;
  width: 100% !important; 
}
.cell
{
	display: table-cell; 
	font-size: 9pt; 
	width: 50pt;
	height: 14pt !important;
	text-align: right;
	padding-top: 0pt;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
}
.cellInp
{
	display: table-cell; 
	font-size: 9pt; 
	width: 95pt;
	height: 14pt !important;
	text-align: right;
	padding-top: 0pt;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
}
.cellTitle
{
	display: table-cell; 
	font-size: 9pt; 
	height: 14pt !important;
	width: 160pt !important;
	padding-top: 0pt;
	padding-left: 1pt;
	font-weight: bold;
	text-align: left;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
	background-color: rgb(0, 128, 192);
	color: #fff;
	overflow-x: hidden;
	white-space: nowrap; /* Don't forget this one */
	text-overflow: ellipsis; 
	
}
.cellTitleNOADTAX {
	display: table-cell; 
	font-size: 9pt; 
	height: 14pt !important;
	width: 160pt !important;
	padding-top: 0pt;
	font-weight: bold;
	text-align: left;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
	background-color: #cce;
	color: #000;
	overflow-x: hidden;
	white-space: nowrap; /* Don't forget this one */
	text-overflow: ellipsis; 
}
.indent {
	padding-left: 5pt;
	font-style: italic;
	font-weight: normal;
}
.cumulative {
	font-style: italic;
	font-weight: normal;
}
.row
{
	display: table-row;
}
.row:hover
{
	background-color: #777;
	color: #fff;
}
.gap5 
{
	border-top: 2px solid #888;
}
.head
{
	font-weight: bold;
	background-color: #3b60c4;
	color: #fff;
	padding-right: 2pt;
}
.head2
{
	font-weight: bold;
}
.centered {
  justify-content: center;
}
.right {
  justify-content: right;
}
.left {
  justify-content: left;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 28pt; 
  font-weight: bold; 
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  x-overflow: hidden;
}

.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}

.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 

.modal-wrapper {
  //display: table-cell;
  
}

.modal-container {
    
  padding-bottom: 20pt;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  //transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
  padding: 5pt;
  height: 100%;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}


.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.colored {
  //background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>
